/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Frame } from "../../icons/Frame";
import "./style.css";

export const FrameWrapper = () => {
  return <Frame className="frame-instance" />;
};
