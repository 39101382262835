/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Frame7 = ({ className }) => {
  return (
    <svg
      className={`frame-7 ${className}`}
      fill="none"
      height="49"
      viewBox="0 0 50 49"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2409_5294)">
        <mask className="mask" height="49" id="mask0_2409_5294" maskUnits="userSpaceOnUse" width="50" x="0" y="0">
          <path className="path" d="M49.2 0H0.199951V49H49.2V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_2409_5294)">
          <path
            className="path"
            clipRule="evenodd"
            d="M12.45 24.5C19.2154 24.5 24.7 19.0155 24.7 12.25C24.7 19.0155 30.1845 24.5 36.95 24.5C30.1845 24.5 24.7 29.9846 24.7 36.75C24.7 29.9846 19.2154 24.5 12.45 24.5ZM12.45 24.5C5.68447 24.5 0.199951 29.9846 0.199951 36.75C0.199951 43.5154 5.68447 49 12.45 49C19.2154 49 24.7 43.5154 24.7 36.75C24.7 43.5154 30.1845 49 36.95 49C43.7154 49 49.2 43.5154 49.2 36.75C49.2 29.9846 43.7154 24.5 36.95 24.5C43.7154 24.5 49.2 19.0155 49.2 12.25C49.2 5.48452 43.7154 0 36.95 0C30.1845 0 24.7 5.48452 24.7 12.25C24.7 5.48452 19.2154 0 12.45 0C5.68447 0 0.199951 5.48452 0.199951 12.25C0.199951 19.0155 5.68447 24.5 12.45 24.5Z"
            fill="#F4F85A"
            fillRule="evenodd"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2409_5294">
          <rect className="rect" fill="white" height="49" transform="translate(0.199951)" width="49" />
        </clipPath>
      </defs>
    </svg>
  );
};
