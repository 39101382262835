/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Frame8 = ({ className }) => {
  return (
    <svg
      className={`frame-8 ${className}`}
      fill="none"
      height="49"
      viewBox="0 0 50 49"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2409_5310)">
        <mask className="mask" height="49" id="mask0_2409_5310" maskUnits="userSpaceOnUse" width="50" x="0" y="0">
          <path className="path" d="M49.7998 0H0.799805V49H49.7998V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_2409_5310)">
          <path
            className="path"
            clipRule="evenodd"
            d="M13.0498 24.5C19.8153 24.5 25.2998 19.0155 25.2998 12.25C25.2998 19.0155 30.7844 24.5 37.5498 24.5C30.7844 24.5 25.2998 29.9846 25.2998 36.75C25.2998 29.9846 19.8153 24.5 13.0498 24.5ZM13.0498 24.5C6.28433 24.5 0.799805 29.9846 0.799805 36.75C0.799805 43.5154 6.28433 49 13.0498 49C19.8153 49 25.2998 43.5154 25.2998 36.75C25.2998 43.5154 30.7844 49 37.5498 49C44.3152 49 49.7998 43.5154 49.7998 36.75C49.7998 29.9846 44.3152 24.5 37.5498 24.5C44.3152 24.5 49.7998 19.0155 49.7998 12.25C49.7998 5.48452 44.3152 0 37.5498 0C30.7844 0 25.2998 5.48452 25.2998 12.25C25.2998 5.48452 19.8153 0 13.0498 0C6.28433 0 0.799805 5.48452 0.799805 12.25C0.799805 19.0155 6.28433 24.5 13.0498 24.5Z"
            fill="#F4F85A"
            fillRule="evenodd"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2409_5310">
          <rect className="rect" fill="white" height="49" transform="translate(0.799805)" width="49" />
        </clipPath>
      </defs>
    </svg>
  );
};
