/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Frame6 = ({ className }) => {
  return (
    <svg
      className={`frame-6 ${className}`}
      fill="none"
      height="49"
      viewBox="0 0 50 49"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2409_5278)">
        <mask className="mask" height="49" id="mask0_2409_5278" maskUnits="userSpaceOnUse" width="50" x="0" y="0">
          <path className="path" d="M49.6001 0H0.600098V49H49.6001V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_2409_5278)">
          <path
            className="path"
            clipRule="evenodd"
            d="M12.8501 24.5C19.6156 24.5 25.1001 19.0155 25.1001 12.25C25.1001 19.0155 30.5847 24.5 37.3501 24.5C30.5847 24.5 25.1001 29.9846 25.1001 36.75C25.1001 29.9846 19.6156 24.5 12.8501 24.5ZM12.8501 24.5C6.08462 24.5 0.600098 29.9846 0.600098 36.75C0.600098 43.5154 6.08462 49 12.8501 49C19.6156 49 25.1001 43.5154 25.1001 36.75C25.1001 43.5154 30.5847 49 37.3501 49C44.1155 49 49.6001 43.5154 49.6001 36.75C49.6001 29.9846 44.1155 24.5 37.3501 24.5C44.1155 24.5 49.6001 19.0155 49.6001 12.25C49.6001 5.48452 44.1155 0 37.3501 0C30.5847 0 25.1001 5.48452 25.1001 12.25C25.1001 5.48452 19.6156 0 12.8501 0C6.08462 0 0.600098 5.48452 0.600098 12.25C0.600098 19.0155 6.08462 24.5 12.8501 24.5Z"
            fill="#F4F85A"
            fillRule="evenodd"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2409_5278">
          <rect className="rect" fill="white" height="49" transform="translate(0.600098)" width="49" />
        </clipPath>
      </defs>
    </svg>
  );
};
