/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const Component = ({ className }) => {
	
	const handleClick = () => {
    const url = 'https://www.mustergalaxy.com/#Jointhewaitlist'; // Your URL with the anchor
    window.open(url, '_blank'); // Opens the URL in a new tab
  };
	
  return (
    <div className={`component ${className}`}>
      <div className="frame-3">
        <div className="frame-4">
          <a href="https://www.mustergalaxy.com"><img className="img" alt="Frame" src="/img/frame-15492.svg" /></a>
          <div className="anchors">
            <a href="#about"><div className="text-wrapper">About</div></a>
            <a href="#howitworks"><div className="text-wrapper">How it works</div></a>
          </div>
        </div>
        <div style={{'cursor':'pointer'}} onClick={handleClick} className="div-wrapper">
          <div className="text-wrapper-2">Sign up</div>
        </div>
      </div>
    </div>
  );
};
