import React, { useState } from 'react';
import { AboutWrapper } from "../../components/AboutWrapper";
import { Component } from "../../components/Component";
import { FrameWrapper } from "../../components/FrameWrapper";
import { Frame10 } from "../../icons/Frame10";
import { Frame5 } from "../../icons/Frame5";
import { Frame6 } from "../../icons/Frame6";
import { Frame7 } from "../../icons/Frame7";
import { Frame8 } from "../../icons/Frame8";
import "./style.css";
import "./App.css"; // Import the CSS file for styling
import Modal from 'react-modal';

export const DesktopIidp = () => {

 const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  
  const handleClick = () => {
    const url = 'https://www.mustergalaxy.com/#Jointhewaitlist'; // Your URL with the anchor
    window.open(url, '_blank'); // Opens the URL in a new tab
  };
  
  const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  modalHeader: {
    margin: '0 0 20px',
    textAlign: 'center',
  },
  modalContent: {
    margin: '0 0 20px',
    textAlign: 'center',
  },
  closeButton: {
    display: 'block',
    margin: '0 auto',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
	color: 'grey',
  },
};

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

  return (
  
    <div className="desktop-IIDP">
      <div className="div-2">
        <div className="card">
          <div className="ellipse" />
          <div className="overlap-group">
            <FrameWrapper />
            <FrameWrapper />
            <FrameWrapper />
			
            <div className="frame-5">
			<span id ="about"></span>
              <p className="unlock-engagement">
                Unlock Engagement with <br />
                Community Rewards Protocol
              </p>
              <div className="frame-6">
                <AboutWrapper about="one" className="frame-15502" icon={<Frame10 className="frame-10" />} />
                <AboutWrapper about="two" className="frame-15502-instance" />
                <AboutWrapper about="three" className="frame-15502-instance" />
                <AboutWrapper about="four" className="frame-15502-instance" />
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap">
            <div className="ellipse-2" />
            <div className="conteny">
			<span id ="howitworks"></span>
              <div className="frame-7" >
                <div className="text-wrapper-3">Configure Your Incentive Campaign</div>
                <p className="dive-into-the-simple">
                  Dive into the simple process of configuring your incentive campaign <br />
                  with the Community Rewards Protocol.
                </p>
              </div>
              <div className=" scroll-container carrousel">
                <div className="frame-8">
                  <div className="frame-9">
                    <div className="frame-11">
                      <div className="frame-wrapper">
                        <Frame5 className="icon-instance-node" />
                      </div>
                      <img className="vector-2" alt="Vector" src="/img/vector-7.svg" />
                    </div>
                    <div className="instruction">
                      <div className="frame-12">
                        <div className="frame-13">
                          <div className="text-wrapper-4">01</div>
                        </div>
                        <div className="text-wrapper-5">First sign up</div>
                      </div>
                      <p className="p">
                        Start by signing up with your email address in order to get access to the Dashboard.
                      </p>
                    </div>
                  </div>
                  <div className="frame-9">
                    <div className="frame-11">
                      <div className="frame-wrapper">
                        <Frame6 className="icon-instance-node" />
                      </div>
                      <img className="vector-2" alt="Vector" src="/img/vector-8.svg" />
                    </div>
                    <div className="instruction">
                      <div className="frame-12">
                        <div className="frame-14">
                          <div className="text-wrapper-4">02</div>
                        </div>
                        <div className="text-wrapper-6">Verify your website</div>
                      </div>
                      <p className="p">This step ensures the integrity and security of your incentive campaign.</p>
                    </div>
                  </div>
                  <div className="frame-9">
                    <div className="frame-11">
                      <div className="frame-wrapper">
                        <Frame7 className="icon-instance-node" />
                      </div>
                      <img className="vector-2" alt="Vector" src="/img/vector-9.svg" />
                    </div>
                    <div className="instruction">
                      <div className="frame-12">
                        <div className="frame-14">
                          <div className="text-wrapper-4">03</div>
                        </div>
                        <div className="text-wrapper-6">Set up campaign</div>
                      </div>
                      <p className="p">
                        Define activities that trigger incentives and set any restrictions or limitations as needed.
                      </p>
                    </div>
                  </div>
                  <div className="frame-9">
                    <div className="frame-11">
                      <div className="frame-wrapper">
                        <Frame8 className="icon-instance-node" />
                      </div>
                      <img className="vector-2" alt="Vector" src="/img/vector-10.png" />
                    </div>
                    <div className="instruction">
                      <div className="frame-12">
                        <div className="frame-14">
                          <div className="text-wrapper-4">04</div>
                        </div>
                        <div className="text-wrapper-6">Top up with Bitcoin</div>
                      </div>
                      <p className="p">
                        Add funds to your campaign by topping up your Bitcoin wallet within the dashboard.
                      </p>
                    </div>
                  </div>
                  <div className="frame-9">
                    <div className="frame-11">
                      <div className="frame-wrapper">
                        <FrameWrapper />
                      </div>
                    </div>
                    <div className="instruction">
                      <div className="frame-12">
                        <div className="frame-14">
                          <div className="text-wrapper-4">05</div>
                        </div>
                        <div className="text-wrapper-6">Start incentivizing</div>
                      </div>
                      <p className="p">
                        Once your campaign is configured and funded, you&#39;re ready to start incentivizing users.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="scrollbar-custom">
                  <div className="bar" />
                </div>
              </div>
              <button className="CTA">
                <div style={{'cursor':'pointer'}} onClick={handleClick} className="text-wrapper-7">Sign up</div>
              </button>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="overlap-2">
            <div className="ellipse-3" />
            <img className="logo" alt="Logo" src="/img/logo.png" />
            <img className="logo-2" alt="Logo" src="/img/logo-1.png" />
          </div>
          <p className="all-rights-reserved">
            All rights reserved&nbsp;&nbsp;2024
            <br />
            Powered by Muster
          </p>
          <div className="links">
            <a href="https://www.mustergalaxy.com/terms.html" target="_blank"><div className="text-wrapper-8">Terms &amp; Conditions</div></a>
            <a href="https://www.mustergalaxy.com/privacy.html" target="_blank"><div className="text-wrapper-8">Privacy</div></a>
           
          </div>
          <div className="social-media">
            <a href="https://twitter.com/Mustergalaxycom" target="_blank"><div className="text-wrapper-8">X</div></a>
            <span className="text-wrapper-8">~togsyn-lablev</span>
          </div>
        </footer>
        <div className="overlap-group-wrapper">
          <div className="overlap-group-2">
            <p className="introducing-a-new">
              INTRODUCING A NEW ERA <br />
              OF USER DATA CONTROL
            </p>
            <div className="frame-16">
              <div className="overlap-group-2">
                
				<video autoPlay muted loop className="vector-3">
				  <source src="/img/12.mp4" type="video/mp4"/>
					Your browser does not support the video tag.
				</video>
                <img className="rectangle" alt="Rectangle" src="/img/rectangle-827-2.svg" />
                <div className="frame-17">
                  <div className="frame-18">
                    <p className="introducing-a-new-2">
                      Introducing a new era
                      <br />
                      of rewards protocol
                    </p>
                    <p className="introducing-a-new-3">
                      Introducing a new era <br />
                      of user data control
                    </p>
                  </div>
                  <img className="union" alt="Union" src="/img/union.svg" />
                  <div className="input-cta">
                    
                    
                    <button onClick={handleClick} className="button">
                      <div className="text-wrapper-10">Sign up</div>
                    </button>
                  </div>
                </div>
                <div className="ellipse-4" />
                <div className="frame-19">
                  <p className="text-wrapper-11">Create your own campaigns in Bitcoin & get website engagements</p>
                </div>
                <div className="frame-20">
                  <div className="a-cute-dummy-text-wrapper">
                    <p className="a-cute-dummy-text">
                      Introducing Bitcoin powered <br />
                      Campaign & Incentives Protocol
                    </p>
                  </div>
                  <div className="img-wrapper">
                    <img className="frame-21" alt="Frame" src="/img/frame-15492-1.svg" />
                  </div>
                </div>
                <p className="incentivize-actions">
                  <span className="span">
                    Incentivize actions, drive interaction, <br />
                    and elevate your website's success.{" "}
                  </span>
                  <span className="text-wrapper-12"> Ready to elevate your website's performance?</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="component-2 zoom-image">
          <div className="overlap-3">
            <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-827-3.svg" />
            <img className="frame-22" alt="Frame" src="/img/frame-15554.svg" />
            <div className="frame-23">
              <img className="frame-24" alt="Frame" src="/img/frame-15541-2.svg" />
              <div className="frame-25">
                <p className="discover-muster-your">
                  Discover Muster <br />
                  Your Gateway to Incentives
                </p>
                <p className="muster-is-the">
                  Muster is the sovereign, Web3 browser that rewards your online activities with real incentives. <br />
                  Download Muster now to start earning rewards while you browse!
                </p>
              </div>
              <button style={{'cursor':'pointer'}} onClick={openModal} className="CTA-2">
                <div className="text-wrapper-7">Download</div>
              </button>
            </div>
          </div>
        </div>
        <Component className="component-1" />
      </div>
	  <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
        contentLabel="Coming Soon Modal"
      >
        <div className="videoWrapper">
          <video autoPlay loop muted className="videoBackground">
            <source src="/img/12.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="modalContentWrapper">
            <h2 className="modalHeader">Coming Soon!</h2>
            <p className="modalContent">We will be releasing soon.</p>
            <button onClick={closeModal} className="closeButton">
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
 
};
