/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Frame = ({ className }) => {
  return (
    <svg
      className={`frame ${className}`}
      fill="none"
      height="49"
      viewBox="0 0 49 49"
      width="49"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_2215_3444)">
        <mask className="mask" height="49" id="mask0_2215_3444" maskUnits="userSpaceOnUse" width="49" x="0" y="0">
          <path className="path" d="M49 0H0V49H49V0Z" fill="white" />
        </mask>
        <g className="g" mask="url(#mask0_2215_3444)">
          <path
            className="path"
            clipRule="evenodd"
            d="M12.25 24.5C19.0155 24.5 24.5 19.0155 24.5 12.25C24.5 19.0155 29.9846 24.5 36.75 24.5C29.9846 24.5 24.5 29.9846 24.5 36.75C24.5 29.9846 19.0155 24.5 12.25 24.5ZM12.25 24.5C5.48452 24.5 0 29.9846 0 36.75C0 43.5154 5.48452 49 12.25 49C19.0155 49 24.5 43.5154 24.5 36.75C24.5 43.5154 29.9846 49 36.75 49C43.5154 49 49 43.5154 49 36.75C49 29.9846 43.5154 24.5 36.75 24.5C43.5154 24.5 49 19.0155 49 12.25C49 5.48452 43.5154 0 36.75 0C29.9846 0 24.5 5.48452 24.5 12.25C24.5 5.48452 19.0155 0 12.25 0C5.48452 0 0 5.48452 0 12.25C0 19.0155 5.48452 24.5 12.25 24.5Z"
            fill="#F4F85A"
            fillRule="evenodd"
          />
        </g>
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_2215_3444">
          <rect className="rect" fill="white" height="49" width="49" />
        </clipPath>
      </defs>
    </svg>
  );
};
