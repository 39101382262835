/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Frame1 } from "../../icons/Frame1";
import "./style.css";

export const AboutWrapper = ({ about, className, icon = <Frame1 className="vector" /> }) => {
  return (
    <div className={`about-wrapper ${about} ${className}`}>
      <div className="div">
        <div className="frame-2">
          {["four", "three", "two"].includes(about) && (
            <img
              className="vector"
              alt="Vector"
              src={
                about === "three" ? "/img/vector-5.svg" : about === "two" ? "/img/vector-6.svg" : "/img/vector-4.svg"
              }
            />
          )}

          {about === "one" && <>{icon}</>}

          <div className="strengthen-user">
            {about === "four" && <>Strengthen User Relationships</>}

            {about === "three" && <>Bitcoin Integration Made Easy</>}

            {about === "two" && <>Rewarding User Actions</>}

            {about === "one" && <>Seamlessly Integrated With Muster</>}
          </div>
        </div>
        <div className="CRP-empowers-website">
          {about === "four" && (
            <p className="text-wrapper-13">
              CRP empowers website owners to cultivate meaningful relationships by fostering user loyalty and deeper
              connections with visitors.
            </p>
          )}

          {about === "three" && (
            <p className="text-wrapper-13">
              Website owners can effortlessly drop incentives in sats to visitors&#39; wallets, enhancing user
              motivation and interaction.
            </p>
          )}

          {about === "two" && (
            <p className="text-wrapper-13">
              CRP tracks user actions, like scrolling and sign-ups, triggering dynamic incentives to promote active
              engagement.
            </p>
          )}

          {about === "one" && (
            <p className="text-wrapper-13">
              Unlock enhanced user engagement and effortless incentives distribution right within the browsing
              experience.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

AboutWrapper.propTypes = {
  about: PropTypes.oneOf(["two", "one", "three", "four"]),
};
